import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { ADMIN_BLOG } from 'Consts/routes';
import { API_RESOURCE_USERS } from 'Consts/apiResources';
import { ENDPOINT_ADMIN_FILE_PRESIGN } from 'Consts/api';
import { ARTICLE_STATUSES } from 'Consts/articles';

import { fromSelectObject } from 'Utils/object';
import { withVariables } from 'Utils/string';
import { getFullName } from 'Utils/user';

import StyledComponent from 'Components/core/StyledComponent';
import ElementEditor from 'Components/layout/panel/ElementEditor';

export default class AdminArticleEditor extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        actions: PropTypes.shape({
            update: PropTypes.func.isRequired,
            create: PropTypes.func.isRequired,
            listUsers: PropTypes.func.isRequired,
            presignFile: PropTypes.func.isRequired,
            remove: PropTypes.func.isRequired,
        }).isRequired,
        data: PropTypes.object,
    };

    state = {
        formState: {},
    };

    componentDidMount = () => {
        const { data } = this.props;

        this.setState(prevState => ({
            formState: {
                ...prevState.formState,
                ...this.dataToFormState(data || {}),              
            },
        }));
    }

    componentDidUpdate = (prevProps, prevState) => {
        const { data } = this.props;

        if (data && JSON.stringify(data) !== JSON.stringify(prevProps.data)) {
            this.setState(prevState => ({
                formState: {
                    ...prevState.formState,
                    ...this.dataToFormState(data || {}),
                },
            }));
        }
    }

    dataToFormState = data => {
        return {
            ...data,
            authorId: data.author
                ? { value: data.author.id, label: getFullName(data.author).label }
                : null,
        };
    }

    formStateToData = formState => {
        return {
            ...formState,
            authorId: fromSelectObject(formState.authorId),
            imageId: formState.image ? formState.image.id : undefined,
            status: fromSelectObject(formState.status),
            isPromoted: formState.isPromoted || false,
        };
    }

    onSubmit = formState => {
        const { data } = this.props;

        return data && data.id
            ? this.onUpdate(formState)
            : this.onCreate(formState);
    }

    onUpdate = formState => {
        const { actions, data } = this.props;

        return actions.update({
            id: data.id,
            ...this.formStateToData(formState),
        });
    }

    onCreate = formState => {
        const { actions, history } = this.props;

        return actions.create({
            ...this.formStateToData(formState),
        }).then(() => {
            history.push(
                withVariables(
                    ADMIN_BLOG.path,
                    {}, 
                    {}
                )
            );
        });
    }

    render() {
        const { location, history, actions, data } = this.props;
        const { formState } = this.state;

        return (
            <StyledComponent
                className="admin-article-editor"
                styles={require('./styles')}
            >
                <ElementEditor
                    location={location}
                    history={history}
                    forms={[{
                        title: 'Dane',
                        name: 'editor',
                        submitAction: this.onSubmit,
                        data: formState,
                        onStateChange: formState => {
                            this.setState({
                                formState,
                            });
                        },
                        elements: [{
                            type: 'select',
                            name: 'authorId',
                            label: 'Autor',
                            inputProps: {
                                dynamic: true,
                                onLoad: query => actions.listUsers({
                                    search: query,
                                }),
                                onChange: value => this.setState(prevState => ({
                                    formState: {
                                        ...prevState.formState,
                                        authorId: fromSelectObject(value)?.value,
                                    },
                                })),
                                onMapResponse: response => response.payload[API_RESOURCE_USERS].elements,
                                onMapOption: element => ({
                                    value: element.id,
                                    label: getFullName(element).label,
                                }),
                            },
                        }, {
                            type: 'input',
                            name: 'title',
                            label: 'Tytuł',
                            required: true,
                        }, {
                            type: 'select',
                            name: 'status',
                            label: 'Status',
                            options: ARTICLE_STATUSES.map(option => ({
                                value: option.key,
                                label: option.label,
                            })),
                        }, {
                            type: 'textarea',
                            name: 'lead',
                            label: 'Wstęp',
                            required: true,
                        }, {
                            type: 'textarea',
                            name: 'body',
                            label: 'Treść',
                            required: true,
                        }, {
                            type: 's3FileUpload',
                            name: 'image',
                            label: 'Zdjęcie',
                            inputProps: {
                                action: actions.presignFile,
                                s3Config: {
                                    presignPath: ENDPOINT_ADMIN_FILE_PRESIGN
                                        .replace('{type}', 'article'),
                                },
                            },
                        }, {
                            type: 'switch',
                            name: 'isPromoted',
                            label: 'Czy artykuł jest polecany?'
                        }],
                    }]}
                    controls={[{
                        visible: Boolean(data),
                        title: 'Usuń',
                        subtitle: 'Artykuł zostanie usunięty',
                        buttonProps: {
                            onClick: () => actions.remove({ id: data && data.id }).then(() => {
                                history.push(
                                    withVariables(
                                        ADMIN_BLOG.path,
                                        {}, 
                                        {}
                                    )
                                );
                            }),
                            children: 'Usuń',
                            confirm: {
                                enabled: true,
                            },
                        },
                    }]}
                />
            </StyledComponent>
        );
    }
}